import React from 'react';
import classnames from 'classnames';
import { SearchContext } from './SearchContext';
import * as styles from './SearchBox.module.scss';
import SearchIcon from "../../assets/search.icon.svg";

export default function SearchBox () {
    const {query, dispatch} = React.useContext(SearchContext);

    // Handle search input
    const doSearch = e => {
        dispatch({type: "SEARCH", query: e.target.value});
    }

    return <div className="field has-addons">
        <div className="control is-expanded has-icons-right">
            <input
                className={classnames(["input", styles.searchInput])}
                type="text"
                value={query}
                onChange={doSearch}
                placeholder="Nach was suchen Sie?"
                autoFocus
            />
            <span className={classnames(["icon", "is-right", styles.searchIconWrapper])}>
            <SearchIcon />
            </span>
        </div>
    </div>
}
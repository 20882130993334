import React from 'react';
import { SearchContext } from './SearchContext';
import ResultItem from './ResultItem';
import GraphicDesign from '../blocks/GraphicDesign';
import Questions from '../blocks/Questions';
import { Breakpoints } from '../../utils/Types';
import useBreakpoint from '../../utils/useBreakpoint';
import * as styles from './SearchResults.module.scss';
import classnames from 'classnames';
import useGdprIsAllowed from '../Gdpr/useGdprIsAllowed';
import { useFlexSearch } from "react-use-flexsearch"
import Axios from "axios";
const lunr = require('lunr');

export default function SearchResults() {

    const [localSearch, setLocalSearch] = React.useState<{store: any, index: any}>({index: "", store: ""});
    const [error, setError] = React.useState<string>("");

    React.useEffect(() => {
        const load = async () => {
            const result = await Axios.get("/search_index.json");
            if (result.error) {
                setError("Beim laden der Suchergebnisse ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es erneut. Tritt der Fehler weiterhin auf, wenden Sie sich bitte an uns! Wir bitten um Ihr Verständnis.");
            }

            if (result.data.de) {
                setLocalSearch({
                    store: result.data.de.store,
                    index: result.data.de.index,
                });
            }
        }

        if (!localSearch.index && !localSearch.store) {
            load();
        }
    });
    
    if (error !== "") {
        return <>{error}</>;  
    } 

    if (!localSearch.index && !localSearch.store) return <></>;

    return <SearchResultsListing index={localSearch.index} store={localSearch.store} />;
}

type SearchResultsListingProps = {
    index: any;
    store: any;
}

function SearchResultsListing(props: SearchResultsListingProps) {
    const {index, store} = props;
    const {query} = React.useContext(SearchContext);
    const [results, setResults] = React.useState([]);

    const trackingAllowed = useGdprIsAllowed("Analyse");
    const timeout = React.useRef(null);

    const fullIndex = React.useMemo(() => ({de: {store, index}}), [store, index]);
    const lunrSearcher = React.useMemo(() => Object.keys(fullIndex).reduce(
        (prev, key) => ({
            ...prev,
            [key]: {
                index:  lunr.Index.load(fullIndex[key].index),
                store: fullIndex[key].store,
                },
            }),
            {}
        ), [fullIndex]);


    React.useEffect(
        () => {
          if (!query || !lunrSearcher) {
            setResults([])
            return
          }
          const lunrIndex = lunrSearcher['de']
          const searchResults = lunrIndex.index.search(query);
          setResults(
            searchResults.map(({ ref }) => {
              return lunrIndex.store[ref]
            })
          );

          if(timeout.current) clearTimeout(timeout.current);
          timeout.current = setTimeout(() => {
                 // track on piwik
                if (_paq && trackingAllowed) {
                    _paq.push(['trackSiteSearch',
                        // Search keyword searched for
                        query,
                        // Search category selected in your search engine. If you do not need this, set to false
                        "Globale Suche",
                        // Number of results on the Search results page. Zero indicates a 'No Result Search Keyword'. Set to false if you don't know
                        searchResults.length
                    ]);
            }
          }, 1000);
         
        },
        [query, lunrSearcher]
      );

    // Listing Breakers
    const [linseTillBreak, setLinesTillBreak] = React.useState(2);
    const listingBreaksElements = [<Questions />, <GraphicDesign />]
    const listingBreaksRows: {[keys in Breakpoints]: number} = {
        mobile: 1,
        tablet: 2,
        desktop: 2,
    }

    // Pagination
    const breakpoint = useBreakpoint();

    const [page, setPage] = React.useState(0);
    const [itemsPerPage, setItemsPerPage] = React.useState(2);
    const itemsPerPageBreakpoints: {[keys in Breakpoints]: number} = {
        mobile: 6,
        tablet: 12,
        desktop: 12,
    }

    React.useEffect(() => {
        if (breakpoint) {
            setLinesTillBreak(listingBreaksRows[breakpoint]);
            setItemsPerPage(itemsPerPageBreakpoints[breakpoint]);
        }
    }, [breakpoint]);

    const changePage = (newPage) => {
        setPage(newPage);
    }

    // PAGINATION handling
    // number of pages extraction
    const pagesArray = [];
    for (let i = 0; i < Math.ceil(results.length/itemsPerPage); i++) {
        pagesArray.push(i);
    }

    // pagination items
    const itemsToDisplayPage = [];
    results.forEach((item, index) => {
        const pageStart = itemsPerPage * page;
        const pageEnd = pageStart + itemsPerPage; 
        if (index >= pageStart && index < pageEnd) {
            itemsToDisplayPage.push(item);
        }
    });

    return <>
        {itemsToDisplayPage.length === 0 && query.length > 2 && <div>
            Leider haben wir unter dem Suchbegriff nichts gefunden.
        </div>}

        {itemsToDisplayPage.map((node, index) => {
            const { url, title, description, tags } = node;
            const image = JSON.parse(node.image);
            
            const itemsPerRow = 3 * linseTillBreak;
            let addition = null;
            if (index % itemsPerRow  === 0 && index >= itemsPerRow &&  index <= itemsPerRow * listingBreaksElements.length) {
                    // Add the breaker here
                    const Element = React.cloneElement(listingBreaksElements[(index/itemsPerRow) - 1])
                    addition = <div key={"listbreaker-" + index} className={classnames([styles.columnOutsideWrapper])} >
                    <div className={styles.outsideWrapper}>
                        {Element}
                    </div>
                </div>
            }

            return [addition, 
                <ResultItem key={url} url={"/" + url} title={title} description={description} tags={tags.split("|")} image={image} />
            ];
            })}


        {pagesArray.length > 1 && <div className="content has-text-right">

        {page >= 0 && <a href="#" onClick={(e) => {changePage(page - 1)}}>
            <svg width="13" height="13" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.094,0.375l6.145,6.027l-6.145,6.092" style={{fill: "none", stroke:"#1B9DDC",strokeWidth: "0.75px", transform: "rotate(180deg) translate(-19px, -13px) "}} />
            </svg>   
        </a>}
        {pagesArray.map(pageNumber => <a 
            key={"page-"+pageNumber} 
            href={"#"} 
            className={classnames([styles.paginationItem], (page === pageNumber) ? styles.paginationItemActive : "")} 
            onClick={(e) => {changePage(pageNumber)}}
        > 
            {(page === pageNumber) ? "Seite " : ""}
            {pageNumber + 1} 
        </a>)}

        {page  !== pagesArray.length - 1 && pagesArray.length >= 2 && <a href="#" onClick={(e) => {changePage(page + 1)}}>
            <svg width="13" height="13" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.094,0.375l6.145,6.027l-6.145,6.092" style={{fill: "none", stroke:"#1B9DDC",strokeWidth: "0.75px", transform: "translate(-13px, 0) "}} />
            </svg>   
        </a>}
        </div>}
    </>
}
import React from "react"
import { Link } from "gatsby";
import * as styles from './ResultItem.module.scss';
import { GatsbyImage } from "gatsby-plugin-image";
import ButtonLink from "../ui/Buttons/ButtonLink";
import classnames from 'classnames';

type Props = {
    url: string;
    title: string;
    description: string;
    tags: Array<string>;
    image: any;
}

export default function ResultItem (props: Props) {
    const {url, title, description, tags, image} = props;

    return (
        <div className={classnames(["columns", "is-vcentered", styles.wrapper])}>
            {image && image.src && <div className={classnames(["column", "is-one-quarter", styles.columnImage])}>
                <Link to={url} ><GatsbyImage image={image.src.childImageSharp.gatsbyImageData} alt="image.alt" /></Link>
            </div>}
            <div className={classnames(["column", styles.columnText])}>
                <div className={classnames(["columns", "is-gapless", styles.titleColumn])}>
                    <div className={classnames(["column"])}>
                        <ButtonLink to={url} variation="minimal" className={styles.button}>{title}</ButtonLink>
                    </div>
                    <div className={classnames(["column", "is-narrow"])}>
                        {tags.map(tag => tag ? <span className="tag is-light">{tag}</span> : null)}
                    </div>
                </div>
                <span className="is-hidden-mobile">{description}</span>
            </div>
        </div>
    );
}
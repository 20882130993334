import React from "react"
import Hero from "../ui/Hero/Hero"
import classnames from 'classnames';
import * as styles from './GraphicDesign.module.scss'
import { useStaticQuery, graphql, navigate } from "gatsby";
import IconGraphicDesignSvg from "../../assets/gestalten.svg";
import ButtonAction from "../ui/Buttons/ButtonAction";
import { CartContext } from "../Cart/CartContext";
import SvgImage from "../SvgImage";


type Props = {
    withFilterMode?: boolean;
    className?: any;
    title?: string;
    text?: string;
}

export default function GraphicDesign (props: Props) {
    const {withFilterMode, title, text} = props;
    const {dispatch} = React.useContext(CartContext);
    const data = useStaticQuery(graphql`{
  product: produkteHJson(url: {eq: "zubehoer/grafik-design"}) {
    name
    meta {
      title
      description
      ogTitle
      ogImage {
        childImageSharp {
          gatsbyImageData(width: 300, height: 300 layout: FIXED)
        }
      }
      ogDescription
    }
    vorteile
    productWidgetKey
    filters {
      typ
      wert
      filter
      widget
      widgetType
      defaultValue
    }
    infos {
      beschriftung
      wert
    }
    kurzbeschreibung
    produktText
    produktTyp
    url
    listenbild {
      alt
      src {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
}
`);

    const addToCart = () => {
        dispatch({type: "ADD_PRODUCT", product: data.product, configuration: {}, qty: 1 });
        navigate("/anfragen");
    }

    return (
        <Hero
            color={"light"}
            hasRoundedCorner={true}
            isExpandingRightOnly={(withFilterMode) ? "IsOneThird" : undefined}
            paddingSize="large"
        >
                <div className={classnames(["columns", " is-vcentered"])}>
                <div className={classnames(["column", "is-half", styles.order1, "is-align-self-stretch", "is-flex", "is-justify-content-center"])}>
                        <div className={classnames(["content", styles.graphicWrapper])}>
                            <SvgImage  src={IconGraphicDesignSvg} alt="Grafische gestaltung" ratio={0.64} />
                        </div>
                    </div>
                    <div className={classnames(["column", styles.order2])}>
                        <div className="content">
                            <p className="title">
                                {title ? title : "Sie brauchen noch die passende Gestaltung?"}
                            </p>
                            <p className="subtitle">
                                {text ? text : "Profitieren Sie von unserem Know-How in Layout und Design mit all den Möglichkeiten in Sachen Veredelung, Beleuchtung und Druck."}
                            </p>

                            <ButtonAction onClick={addToCart}>
                                Kontaktieren Sie uns
                            </ButtonAction>
                        </div>
                    </div>

 
                </div>
        </Hero>
    )
}
